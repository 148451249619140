import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components"
import GlobalNavigation from "./global-nav/global-nav"
import PropTypes from "prop-types"
import { base } from "./styles/ColorStyles"
import "./layout.css"

const ContentWrapper = styled.div`
  margin: 0;
 
`

const BlogLayoutWrapper = styled.div`
  margin: 0 auto;
   max-width: 832px;
  padding: 4rem 1rem;

  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }
  @media (max-width: 426px) {
    padding: 3rem 1rem;
  }
`

const PostLayout = ({ children }) => {
  return (

      <ContentWrapper>
        <GlobalNavigation />
        <BlogLayoutWrapper>
          <MDXProvider
            components={
              {
                // Map HTML element tag to React component
                // li: props => <Li {...props} />,
              }
            }
          >
            {children}
          </MDXProvider>
        </BlogLayoutWrapper>
      </ContentWrapper>

  )
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostLayout
