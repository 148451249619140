import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import VideoPlayer from "../../components/post/video";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Punchy `}<br />{` An AI boxing assistant for amateur boxers to improve their skills`}</h1>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.66666666666666%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABVor8bxIBP//EABkQAAIDAQAAAAAAAAAAAAAAAAECAxEhE//aAAgBAQABBQIyxqzrQXQDZkbexv8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAECAQE/AUv/xAAdEAACAQQDAAAAAAAAAAAAAAAAARECECExEkGR/9oACAEBAAY/AuLTwONmaoZ15bSP/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFB/9oACAEBAAE/IWFhiwhNXiPxzEjrdqL3qJ0DRmciCVrb2f/aAAwDAQACAAMAAAAQP+//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QNZ//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EEDlq//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFhMUGBof/aAAgBAQABPxAb1LWCUwq0xTng26j4FVh7jWCsBPqHFlcuWHT7EEOYLrXyf//Z')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Trulli",
        "title": "Trulli",
        "src": "/static/2e838a00853aabecb1642a24d4619cd7/e5166/cover.jpg",
        "srcSet": ["/static/2e838a00853aabecb1642a24d4619cd7/f93b5/cover.jpg 300w", "/static/2e838a00853aabecb1642a24d4619cd7/b4294/cover.jpg 600w", "/static/2e838a00853aabecb1642a24d4619cd7/e5166/cover.jpg 1200w", "/static/2e838a00853aabecb1642a24d4619cd7/d9c39/cover.jpg 1800w", "/static/2e838a00853aabecb1642a24d4619cd7/df51d/cover.jpg 2400w", "/static/2e838a00853aabecb1642a24d4619cd7/08688/cover.jpg 2500w"],
        "sizes": "(max-width: 1200px) 100vw, 1200px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <div style={{
      "maxWidth": "840px",
      "margin": "0 auto"
    }}>
      <h2>{`Overview`}</h2>
      <p>{`In 2020 I took my first foray into experimenting with tinyML models as tensorflow released compatible models for the Arduino nano BLE sense. Running classification models on embedded devices could mean no data would need to be transferred out of the device. Potential applications in the future could mean harnessing ML capablities without private data needed to be exchanged.`}</p>
      <p>{`I built a fun punch classifier inspired by the wearable watches that count steps but share private data back to the mothership. My intention was to protoype a functioning excercise simulator that could be used offline.`}</p>
      <h2>{`My role`}</h2>
      <p>{`Product Design, embedded programming, web development. `}<b>{`(2019, 2 weeks)`}</b></p>
      <VideoPlayer linkHash="L14mzPaCUIY" mdxType="VideoPlayer"></VideoPlayer>
      <p>{`View the video with audio for sounds of delight :)`}</p>
      <hr></hr>
      <h2>{`Design Process`}</h2>
      <h3>{`Gathering Data`}</h3>
      <p>{`To train the model I needed to know how the hand moved. So I collected 6 parameters in a single punch sample. The X, Y, Z positions using the onboard `}<b>{`accelerometer`}</b>{` and the orientations using the `}<b>{`gyroscope`}</b>{`.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACRElEQVQ4y42TW0tUURTHz1fqoS8R9Rg9CL2YlErRk1j4FEnDgDNeRsfLTF6gNCcsKlIjoodCnbRx8CMUBDme0Zl9PbdfnD0gIcG0Dn/WWvv812Lt/97b+/z1Cwf7+1SrVYednR3na7Ua9Xqdg2qV0dV1Pu3t8v0Cb293z/EODw+pfdsn8+IlntSabnYsJToIuvJOtMY7bfrolsCkRW2BEcLlVip0W6KFwGqFbAtE47f7L30f024hGw3Hs1JipCAwBq/ZbGKlwGiDVanXWC0xJs07XrTbtIVANU/cmvIbGOd9rFIERmK0wqYN4zjuupUzpUjobiqM8B6N55leXGSyXGZmZYWZlWUKS8tMLS0zXn5Kbr7E1Xv3GZ2ZJjc3R7Y4S75UZqJUYmxhwcXZYtHxLvfcxMtPTTKayfA4k2GqOEthdpp8ocDk/ALZXI4n2Sxj4xO829zk2doaz9crvN3aZvvjFq823/Nm+wNrlQqrlQobGxt4vxrHRGFIYK3TT6UCa+0EjqPIafS6fkTE/5knRRsnUBJDnAYxJMlfOYhU+CAg1TsMQ4c0joKYKArdQFEUOXjNEx/ln7nrkJ5aenWUf4oREtU8JbAGpRShDc4RWeuahMYSBZYkTnBfkuC1zloEUhIGAaEUne0r2SnQijAMSKLIFcXplGmcdOIkConD2OWpuYZnrVZXXY5+/Dx/KWnROS7kqXbe9Vu99AwMcONOP/0PR7g7Mkzv0BB9ww/oGRzket9tLl25xrHvuymttU7Pf0Ebyx9zZLD0yxddJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Arduino data visualization screenshots",
            "title": "Arduino data visualization screenshots",
            "src": "/static/e41c6482e7142e7324707476fc4d498a/c1b63/serial.png",
            "srcSet": ["/static/e41c6482e7142e7324707476fc4d498a/5a46d/serial.png 300w", "/static/e41c6482e7142e7324707476fc4d498a/0a47e/serial.png 600w", "/static/e41c6482e7142e7324707476fc4d498a/c1b63/serial.png 1200w", "/static/e41c6482e7142e7324707476fc4d498a/d61c2/serial.png 1800w", "/static/e41c6482e7142e7324707476fc4d498a/97a96/serial.png 2400w", "/static/e41c6482e7142e7324707476fc4d498a/7a8f9/serial.png 2988w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Arduino data visualization`}</figcaption>
      </figure>
      <p>{`The first three images above are graphical representations of what a `}<b>{`Jab`}</b>{`, a `}<b>{`hook`}</b>{` & a `}<b>{`uppercut`}</b>{` look from a sensors perspective. The last image shows all of them plotted in the same graph.`}</p>
      <h3>{`Training Model`}</h3>
      <p>{`Upon gathering instances of the data I trained it using a sample Google colab notebook by Don Coleman. This process took a couple of tries since I needed to test it with couple of people to make sure I remove any potential bias that may arise.`}</p>
      <VideoPlayer linkHash="-4gt8aVh8WE" mdxType="VideoPlayer"></VideoPlayer>
      <VideoPlayer linkHash="nd6ZfLrNmFo" mdxType="VideoPlayer"></VideoPlayer>
      <h3>{`Front-end Application`}</h3>
      <p>{`The gesture classification is happening on the Arduino, which is a physical component without any internal audio/visual feedback tooling. Attaching a small LCD screen to the Arduino was a option but it presented itself with some issues.`}</p>
      <p>{`So I decided to gamify it with a screen based dashboard:`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVQoz7WSv0/CQBTHL7bXX9eW9nptCYMShUoMSMAfA5OrCQPBuDi4uBg1jv77H3NgMCYsiA6f4b7J+7x7eU+M+oekKkQIsTNJotB5C1NkHDfnDMYzRDdWXJuciW5xZXJGeYupzpiVBSetBOn7eFJuFQ77XabjAZNhn9v5A4vHD4SKY6qypK4q0iQhCAIC3ycKwxX27XneVmGlM0bNESfdDheTS07PpjYX/BbHOSDwXAJfEkWKIIwQtnv49RPXddmnwQpjDL1ej6Zp6HQ6+wv/QPCTMkwwZYkpCgqLKdC5RumUojLrbIMmSQ26MBjzndsplVJrYRan1HVNlmWrbVqkK4liRd2uVwW+PR2bS0kQ2rxNZa8iTTc1juP808g3E8HzQvCyFLzd7cbrUvB+L3iaC6SzFn4CF6z82jcwMx0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Punchy home dashboard screenshots",
            "title": "Punchy home dashboard screenshots",
            "src": "/static/f15862ef5a253a7f8222b67972e5b184/c1b63/home.png",
            "srcSet": ["/static/f15862ef5a253a7f8222b67972e5b184/5a46d/home.png 300w", "/static/f15862ef5a253a7f8222b67972e5b184/0a47e/home.png 600w", "/static/f15862ef5a253a7f8222b67972e5b184/c1b63/home.png 1200w", "/static/f15862ef5a253a7f8222b67972e5b184/d61c2/home.png 1800w", "/static/f15862ef5a253a7f8222b67972e5b184/97a96/home.png 2400w", "/static/f15862ef5a253a7f8222b67972e5b184/eb645/home.png 2500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Punchy home dashboard`}</figcaption>
      </figure>
      <ul>
        <li parentName="ul">{`It informed the user what were the possible actions they could take.`}</li>
        <li parentName="ul">{`A set timer to build a time scarcity in order to keep users active during the activity.`}</li>
        <li parentName="ul">{`Animated gifs as an additional feedback as a delightful feedback on taking a certain action.`}</li>
      </ul>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABpklEQVQoz52QO2/TYBSGz2fH98auc2sTShwgbp0Sk6RchqICRRUsDExAoRswlP9ABKISSFAhMYJYusHEXCFBGSoGNn7Qg+yEqQFBhmc4Ojrv5UgaNwk8BxH5L3Rd58r6VW7f2eTW3S02722xduky0ppxuVAJGZQCzldC0jBgpTTLarVMOyhiWhamYRwRNAyDd+/3ODz4yue3u/z88o03r14jnudRq1aZq9XwfR/btrEtC9dxcrLZNM0jgpnJweF3fuzvs5cu8unDR3Ze7GY7YRqyyr3+gN7pLh3bZWkpodmMkMzdGSexLCtnWpOcSrlM3G4TnxqzEKGJGi3VBPJDlaNp+giloWkaSqnpK/+R61LnuerzWHUZSspQS3kiy7TiBrKdIA8S5GGC3E+Q7ZjC2S7H4yFRf4fozDOi9CknBi8pHbsxEmw5IWthixVnjo74dFRAIjPMN2pEGz0aFxO8tI7fX6A4aFBpn2Sxc416c5Xy/DncYBl3NsW0679TTo6e/aZYcHB1C0cZeAUbV7NwTQvT/Evl9YHw6KZQtMdCIqh/+peagPALZssQZ/L/u/IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sucessful jab feedback screenshot",
            "title": "Sucessful jab feedback screenshot",
            "src": "/static/2790a468a9783451fc78a21b7bbd0284/c1b63/jab.png",
            "srcSet": ["/static/2790a468a9783451fc78a21b7bbd0284/5a46d/jab.png 300w", "/static/2790a468a9783451fc78a21b7bbd0284/0a47e/jab.png 600w", "/static/2790a468a9783451fc78a21b7bbd0284/c1b63/jab.png 1200w", "/static/2790a468a9783451fc78a21b7bbd0284/d61c2/jab.png 1800w", "/static/2790a468a9783451fc78a21b7bbd0284/97a96/jab.png 2400w", "/static/2790a468a9783451fc78a21b7bbd0284/eb645/jab.png 2500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Sucessful jab feedback screenshot`}</figcaption>
      </figure>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABuklEQVQoz62Rv2sTYRjHn/eSu95dflwv14vGQO5Srm2SejWJ1aE66CRIFi0FhajFoQqmJaB0kFpaFKx20cE/QPAPEJwquDk5uTg4OYmjf8JHcok6aAeDw4fnC8/zfp/vwyvxTAUnYyEi/0Q6pbjWqdG/3ma9e4KNbpsrF+tImLU5NeXSKjhJjV2HdmGSJd8jcnIYExMYuv7LSI2qbab59m4VPtzm+9s+fNnk85sbSCaToej7HCkWyedzmKaZYNsWtmUl2jCMvxhqfD24ysHLXZ7vPePj+1d8et0d9IRxMHXFi82T3N+6x2p/i72nT9i/ex4ZbLdGSXRdT9IMdDqdHmuRTHkeM1HEbBQRBiG1Wky93sZ1/eGAUoiS3yQPVYKmaWhaCqWlhnrYH+/kQ7mgSjxWTR6o42yrmG0tZkfmqc6WkV4DtdZAbjWQtXmkN4dxeoFgboeg+Yhg4SFBvEu1tU+h3BkahpbLWTekaRapSZ66cqhJFrfgkF+skI2PoaYdpJpDprMYvke5coZSsIR3dBHbaWBPxhhm6WfK/3zyuZZw55LQuyxsLAvry0J/RbjZEXR9NHTIp/yJ8AMbvBkAIebmkwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sucessful uppercut feedback screenshot",
            "title": "Sucessful uppercut feedback screenshot",
            "src": "/static/ba0a8b52d890e2250d2120d66387aa83/c1b63/uppercut.png",
            "srcSet": ["/static/ba0a8b52d890e2250d2120d66387aa83/5a46d/uppercut.png 300w", "/static/ba0a8b52d890e2250d2120d66387aa83/0a47e/uppercut.png 600w", "/static/ba0a8b52d890e2250d2120d66387aa83/c1b63/uppercut.png 1200w", "/static/ba0a8b52d890e2250d2120d66387aa83/d61c2/uppercut.png 1800w", "/static/ba0a8b52d890e2250d2120d66387aa83/97a96/uppercut.png 2400w", "/static/ba0a8b52d890e2250d2120d66387aa83/eb645/uppercut.png 2500w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`Sucessful uppercut feedback screenshot`}</figcaption>
      </figure>
      <hr></hr>
      <h2>{`Showcase | ITP Winter Show 2019`}</h2>
      <p>{`I had a chance to showcase my project at the ITP winter show 2019. Which let put the project up for 100+ potential users & get a feedback on the interactions.`}</p>
      <figure>{`
  `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAABYlAAAWJQFJUiTwAAACMElEQVQozwElAtr9AGdqcXp7gri1s9LRzd7b1tjY1tbNxbKGWcGVXsqdYq6FV6x+UJ5yTZuHc413WKGGY51/WpqAW6+aeKGQdQBnd5A2OD+fm5Z3dXQZGBpDSE3RyL7HllSCZDp0YkPCoGO+hEbHkVR6b18XHiOrkmnHqXWZeVNsTzRiSzAAVFpmSURCs6igk42LXmBaXF1czMK3spFkZE45WEgxu6V706x25bhziYNsOzoynJeIpYtjWUUybEssgFMrAISDh7i0sriysKGXk5GLhpWPi6+nnlZMQUkyHXdJIItkPsqdY9KTWodzXUk1Hp6EYIlpQVs9IlI8IoVdNgCwpJa0pZK6qZi8rp68saGsnofAtqWgjnW4nnm3m3rCpH+9oHu4l3ifkYZ9bFu0nHq3l3OlhGWDc1+ki28AxKh3yKZnw59jooBPlmxAcEkjuqKA0bmJxq590byKh3BOZlE4uJ54p5iAvaNu2L2E2L2DvqFurIxfooNZAKqXcktENoV5XcCnc5p9WEo8Hq+jf9W6hzcxMDo4MHRVPXRdN9zIl7epj8+2frGfd1hTSJV/XnRXNbymewCIf201MSltZVLCsIqZg1iCbUCGini3sqFnV0dgUzCWg0aDczebjFuwoIWVhWOBeW1aSTaVgmVLPzZQQy8AZWFZVDseknZQspp1o31QV0MmaGxlZ2xzVjwlbVU0eG07Wk8oYlQ1mYpyfWpLiXBSnHVIclw/GhgWFBIORcoD1mPep8UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "People trying out puncy at the ITP winter show 2019",
            "title": "People trying out puncy at the ITP winter show 2019",
            "src": "/static/cf0c8d5e08b9caf804b419bb200eceef/c1b63/itp.png",
            "srcSet": ["/static/cf0c8d5e08b9caf804b419bb200eceef/5a46d/itp.png 300w", "/static/cf0c8d5e08b9caf804b419bb200eceef/0a47e/itp.png 600w", "/static/cf0c8d5e08b9caf804b419bb200eceef/c1b63/itp.png 1200w", "/static/cf0c8d5e08b9caf804b419bb200eceef/d61c2/itp.png 1800w", "/static/cf0c8d5e08b9caf804b419bb200eceef/97a96/itp.png 2400w", "/static/cf0c8d5e08b9caf804b419bb200eceef/fa3b0/itp.png 3340w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="figure">{`People trying out puncy at the ITP winter show 2019`}</figcaption>
      </figure>
      <p>{`You can see me presenting it on the `}<a parentName="p" {...{
          "href": "https://youtu.be/11M3RLmayz8?t=275"
        }}>{`coding train youtube channel`}</a>{`.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      